import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React from 'react';
import Slider from 'react-slick';

import { COLORS } from '../../constants';
import { Breakpoint } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { NoSelect } from '../UI/NoSelect';
import { Text } from '../UI/Text';

import AnimatieSymbol from './AnimatieSymbol.svg';
import AppsSymbol from './AppsSymbol.svg';
import CopywritingSymbol from './CopywritingSymbol.svg';
import FotoVideoSymbol from './FotoVideoSymbol.svg';
import WebsitesSymbol from './WebitesSymbol.svg';

const settings = {
  dots: false,
  infinite: true,
  speed: 900,
  arrows: false,
  autoplay: true,
  slidesToShow: 3,
  slidesToScroll: 1
};

export function Activities() {
  return (
    <StyledSlider {...settings}>
      <>
        <ListItem>
          <Symbol src={AppsSymbol} alt="App" />
          <Text mode="normal">Apps</Text>
        </ListItem>
      </>
      <>
        <ListItem>
          <Symbol src={WebsitesSymbol} alt="Websites" />
          <Text mode="normal">Websites</Text>
        </ListItem>
      </>
      <>
        <ListItem>
          <Symbol src={AnimatieSymbol} alt="Animatie" />
          <Text mode="normal">Animatie</Text>
        </ListItem>
      </>
      <>
        <ListItem>
          <Symbol src={FotoVideoSymbol} alt="Video" />
          <Text mode="normal">Foto &amp; Video </Text>
        </ListItem>
      </>
      <>
        <ListItem>
          <Symbol src={CopywritingSymbol} alt="Copywriting" />
          <Text mode="normal">Copywriting</Text>
        </ListItem>
      </>
    </StyledSlider>
  );
}

const StyledSlider = styled(Slider)`
  width: 750px;
`;

const ListItem = styled.div`
  ${NoSelect()}
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${COLORS.MediumGray};

  padding: 1.2rem 0rem 1.2rem 0rem;
  margin: 0.5rem 1rem .5rem 0rem;
`;

const Symbol = styled.img`
  ${forBreakpoint(Breakpoint.Phone,
  css`
    width: 20px;
    height: 40px;
    margin-right: 0.5rem;
  `)};

  ${forBreakpoint(Breakpoint.TabletLandscape,
    css`
      width: 30px;
      margin-right: 1rem;
  `)};
`;