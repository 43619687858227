import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import React from 'react';

import { Contact } from '../components/ContactButton/Contact';
import { Activities } from '../components/Diensten/Activities';
import { ProjectsCarousel } from '../components/ProjectsCarousel/ProjectsCarousel';
import { Root } from '../components/Root';
import { Column } from '../components/UI/Column';
import { Heading } from '../components/UI/Heading';
import { Row } from '../components/UI/Row';
import { Text } from '../components/UI/Text';
import { COLORS } from '../constants';
import { Breakpoint } from '../types';
import { forBreakpoint } from '../utils/forBreakpoint';
import { getImageByAlt } from '../utils/getImageByAlt';

export const imagesQuery = graphql`
   query media{
    sanityMedia {
      Gallery {
        Alt
        asset {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
    }
   }
  `;

export default ({ data }) => {

  const images = data.sanityMedia.Gallery;

  const branding = getImageByAlt(images, 'branding');
  const websites = getImageByAlt(images, 'websites');
  const copywriting = getImageByAlt(images, 'copywriting');
  const apps = getImageByAlt(images, 'apps');
  const animatie = getImageByAlt(images, 'animatie');
  const fotografie = getImageByAlt(images, 'fotografie');

  return (
    <Root includeFooter={true}>
      <StyledHeader>
        <Column breakpoints={{ [Breakpoint.Desktop]: 4 }} >
          <StyledHeading type="h2">Dit is wat we doen.</StyledHeading>
        </Column>
        <Column breakpoints={{ [Breakpoint.Desktop]: 8 }} >
          <Activities />
        </Column>
      </StyledHeader>
      <Row>
        <Column breakpoints={{ [Breakpoint.Desktop]: 7 }} >
          <ImageContainer fluid={apps.asset.fluid} />
        </Column>
        <Column breakpoints={{ [Breakpoint.Desktop]: 5 }} id={'apps'}>
          <TextContainer >
            <StyledText mode="normal">
              <Heading type="h2">Apps</Heading>
            Vandaag de dag heeft zo goed als iedereen een smartphone. Een eigen app zorgt er dus voor dat je nog dichterbij je klanten kan komen.
            Met een op maat gemaakte app, volledig in de huisstijl van jouw bedrijf, kan je je klanten vaak nog beter van dienst zijn.
            Crossworx zorgt ervoor: jouw app, onze expertise.
          </StyledText>
          </TextContainer>
        </Column>
      </Row>
      <Row direction="row-reverse">
        <Column breakpoints={{ [Breakpoint.Desktop]: 7 }} id="websites">
          <ImageContainer fluid={websites.asset.fluid} />
        </Column>
        <Column breakpoints={{ [Breakpoint.Desktop]: 5 }} >
          <TextContainer>
            <StyledText mode="normal">
              <Heading type="h2">Websites</Heading>
            Je wil een website die er niet alleen goed uitziet, maar vooral ook functioneel is. Bovendien wil je dat poteniële klanten jullie vinden op het wereldwijde web. 
            Wij zorgen voor een knap design, een hoge functionaliteit én een sterke SEO (Search Engine Optimalisation). 
          </StyledText>
          </TextContainer>
        </Column>
      </Row>
      <ExtraServicesContainer>
        <Row>
          <ExtraServiceColumn direction="column" breakpoints={{ [Breakpoint.Desktop]: 5.8 }} id={'branding'}>
            <ImageContainer fluid={branding.asset.fluid} />
            <ExtraServiceTextContainer>
              <Heading type="h2">Branding</Heading>
              <StyledText mode="normal">
                Is je huidige design wat <i>outdated</i>? Nog geen tijd gehad om na te denken over een huisstijl? Een sterk merk steunt op een sterke branding.
                Van logo tot website, van businesskaartjes tot gadgets. Wij steken jouw merk in een mooi jasje.
              </StyledText>
            </ExtraServiceTextContainer>
          </ExtraServiceColumn>
          <Column direction="column" breakpoints={{ [Breakpoint.Desktop]: 5.8 }} id={'photoVideo'}>
            <ImageContainer fluid={fotografie.asset.fluid} />
            <ExtraServiceTextContainer>
              <Heading type="h2">Foto & video</Heading>
              <StyledText mode="normal">
                Eerste indrukken zijn allesbepalend. Met een sterk beeld of een knappe video maak je meteen een goede eerste indruk.
                Je klanten krijgen meteen een sneak peak van hoe jouw bedrijf werkt en wat je belangrijk vindt.
                Een persoonlijke touch zorgt ervoor dat klanten zich uniek en gezien voelen en dat je belang hecht aan persoonlijkheid.
                Wij maken voor jou de mooiste foto’s en de indrukwekkendste video’s om zo vanaf de eerste blik al bij je klanten te blijven hangen.
              </StyledText>
            </ExtraServiceTextContainer>
          </Column>
        </Row>
        <Row>
          <ExtraServiceColumn direction="column" breakpoints={{ [Breakpoint.Desktop]: 5.8 }} id={'copywriting'}>
            <ImageContainer fluid={copywriting.asset.fluid} />
            <ExtraServiceTextContainer>
              <Heading type="h2">Copywriting</Heading>
              <StyledText mode="normal">
                Jij hebt de boodschap, wij vertellen het. Op je website, in je folder, op je social media... overal gebruik je teksten om klanten te overtuigen van jouw verhaal.
                Met onze vlotte en pakkende teksten neem je iedereen mee in jouw verhaal. 
              </StyledText>
            </ExtraServiceTextContainer>
          </ExtraServiceColumn>
          <Column direction="column" breakpoints={{ [Breakpoint.Desktop]: 5.8 }} id={'animatie'}>
            <ImageContainer fluid={animatie.asset.fluid} />
            <ExtraServiceTextContainer>
              <Heading type="h2">Animatie</Heading>
              <StyledText mode="normal">
                Zet  je boodschap kracht bij door middel van leuke animaties. Op een visueel knappe manier, vertel je klanten jouw verhaal.
                Perfect om een duidelijke boodschap over te brengen.
              </StyledText>
            </ExtraServiceTextContainer>
          </Column>
        </Row>
      </ExtraServicesContainer>
      <ProjectsCarousel carouselTitle={'Ontdek onze projecten.'} forProject={false} />
      <ContactContainer>
        <ContactHeading type="h1">
          Meteen aan de slag?
        </ContactHeading>
        <ContactText mode="normal" align="center">
          Op onze contactpagina vind je alles wat je nodig hebt om je project van de grond te krijgen.
        </ContactText>
        <Contact
          displayText={true}
          buttonTitle="Contacteer ons"
          buttonColor="green"
          phoneNumber="+32 412 34 56 78"
          phoneFontColor="white"
        />
      </ContactContainer>
    </Root>
  );
};

const StyledHeader = styled(Row)`
  margin-bottom: 4rem;
`;

const StyledText = styled(Text)`
  font-weight: 100;
  line-height: 1.5rem;
`;

const StyledHeading = styled(Heading)`
  font-weight: bold;
  margin-right: auto;
`;

const ImageContainer = styled(Img)`
${forBreakpoint(Breakpoint.Phone,
  css`
    background-color: ${COLORS.MediumGray};
    width: 100%;
    height: 400px;
  `)};
${forBreakpoint(Breakpoint.Desktop,
    css`
    margin-bottom: 1rem;
  `)};
`;

const TextContainer = styled.div`
  ${forBreakpoint(Breakpoint.Phone,
  css`
      margin-bottom: 3rem;
    `)};
  ${forBreakpoint(Breakpoint.TabletLandscape,
    css`
      margin: 3rem;
    `)};
`;

const ExtraServicesContainer = styled.div`
  margin-bottom: 3rem;
  
  ${forBreakpoint(Breakpoint.TabletLandscape,
  css`
    margin-top: 6rem;
    justify-content: space-between;
  `)};
`;

const ExtraServiceColumn = styled(Column)`
  ${forBreakpoint(Breakpoint.TabletLandscape,
  css`
    margin-right: 2.2rem;
  `)};
`;

const ExtraServiceTextContainer = styled.div`
  margin-bottom: 3rem;

  ${forBreakpoint(Breakpoint.Desktop,
  css`
      margin-top: 1rem;
      max-width: 400px;
  `)};
`;

const ContactContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10rem;
  margin-top: 10rem;
`;

const ContactHeading = styled(Heading)`
  color: #A5C7C6;
  margin-bottom: 3rem;
`;

const ContactText = styled(Text)`
  max-width: 22rem;
  margin-bottom: 3rem;
`;
